import domReady from '@roots/sage/client/dom-ready';
import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import { gsap } from "gsap";




/**
 * Application entrypoint
 */
domReady(async () => {
  // ...

  const btnSeeAvailble = document.querySelector('#btnSeeAvailble')
  const btnApply = document.querySelector('#btnApply')
  const btnFloorplan = document.querySelector('#btnFloorplan')
  const deckContact = document.querySelector('#deckContact')
  const deckSeeAvail = document.querySelector('#availApartments')
  btnApply.addEventListener('click', () => {
    deckContact.scrollIntoView({ behavior: "smooth" });
  })
  btnSeeAvailble.addEventListener('click', () => {
    deckSeeAvail.scrollIntoView({ behavior: "smooth" });
  })
  btnFloorplan.addEventListener('click', () => {
    deckFloorplan.scrollIntoView({ behavior: "smooth" });
  })



  const floorplanBtns = document.querySelectorAll('.floorplan-btn')
  floorplanBtns.forEach(btn => {
    btn.addEventListener('click', (e) => {
      document.querySelectorAll('.floorplan-btn').forEach(elm => {
        elm.classList.remove('btn-active')
      })

      document.querySelectorAll('.floorplan').forEach(elm => {
        elm.classList.remove('floorplan--active')
      })
      e.target.classList.add('btn-active')
      document.querySelector('#' + e.target.dataset.id).classList.add('floorplan--active')

    })
  })

  const galleries = document.querySelectorAll('.lightgallery')

  galleries.forEach(gallery => {
    lightGallery(gallery, {
      plugins: [lgZoom, lgThumbnail],
      speed: 500,
      download: false,
    });
  })



  const heroImage = document.querySelector('.hero-image')
  const heroTl = gsap.timeline()

  heroTl
    .fromTo(heroImage, { scale: 1.1, opacity: 0 }, { scale: 1, opacity: 1, duration: 2, ease: 'power3.out' })
    .fromTo('.fadeIn', { y: 20, opacity: 0 }, { y: 0, opacity: 1, duration: 2, ease: 'power3.out' }, '<.25')



  const contact = () => {

    document.querySelector('#deckContact').scrollIntoView({ behavior: "smooth" });

  }





  const options = {}

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {

        const tl = gsap.timeline()
        const target = entry.target
        tl.fromTo(target, { opacity: 0, y: 100 }, { y: 0, opacity: 1, ease: 'power3.out', duration: 1 });

        observer.unobserve(entry.target)
      }
    });
  }, options);

  const fadeUp = document.querySelectorAll('.fadeUp')

  fadeUp.forEach(elm => observer.observe(elm))


  console.clear()



  const scrollLink = document.querySelector('.scrollToContact')
  const contactForm = document.querySelector('.contactForm')

  scrollLink.addEventListener('click', () => {
    contactForm.scrollIntoView({ behavior: "smooth" });
  })


})

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
